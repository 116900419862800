import React, {useEffect, useState} from 'react';
import moment from 'moment';

const days = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];

const PartnerCustomCalendar = ({ partner, cleanings, date }) => {
  const [value, setValue] = useState(date);
  const [welcomeTime, setWelcomeTime] = useState();

  const startOfWeek = date.clone().startOf('isoWeek');
  const endOfWeek = date.clone().endOf('isoWeek');

  const requestedDays = partner?.requested_days
    ? (typeof partner.requested_days === 'string'
      ? JSON.parse(partner.requested_days)
      : partner.requested_days)
    : [];

  const getCleaningsWithoutTime = (cleanings) => {
    const filteredCleanings = cleanings.filter(item => {
      const cleaningDate = moment(item.date_real ?? item.date_expected);
      return cleaningDate.isSameOrBefore(endOfWeek) && cleaningDate.isSameOrAfter(startOfWeek);
    });

    return days.reduce((result, day, index) => {
      result[index] = filteredCleanings.filter(item => {
        const dayIndex = moment(item.date_real ?? item.date_expected).isoWeekday() - 1;
        return dayIndex === index;
      });
      return result;
    }, {});
  };

  /* date_expected */
  const getCleaningsWithTime = (cleanings) => {
    const filteredCleanings = cleanings.filter(item => {
      const cleaningDate = moment(item.date_real ?? item.date_expected);
      return cleaningDate.isSameOrBefore(endOfWeek) && cleaningDate.isSameOrAfter(startOfWeek);
    });

    return days.reduce((result, day, index) => {
      result[index] = filteredCleanings.filter(item => {
        const dayIndex = moment(item.date_real ?? item.date_expected).isoWeekday() - 1;
        return dayIndex === index;
      });
      return result;
    }, {});
  };

  const withoutTime = getCleaningsWithoutTime(cleanings);
  const withTime = getCleaningsWithTime(cleanings);

  useEffect(() => {
    const updatedWelcomeTime = {};

    Object.entries(requestedDays).forEach(([day, dayArray]) => {
      const dayIndex = days.indexOf(day);
      if (dayIndex !== -1 && requestedDays[day]) {
        updatedWelcomeTime[dayIndex] = requestedDays[day].map((timeRange) => {
          return timeRange ? timeRange.map(time =>
            time ? moment(time).format('HH:mm') : ''
          ) : '';
        });
      }
    });
    setWelcomeTime(updatedWelcomeTime);
  }, [partner]);

  useEffect(() => {
    setValue(date);
  }, [date]);

  const isTimeWithinRange = (welcomeTime, dayIndex, hour) => {
    const ranges = welcomeTime ? welcomeTime[dayIndex] : false;

    if (!ranges || !Array.isArray(ranges) || ranges.length === 0) {
      return false;
    }

    const timeToCheck = moment(hour, "HH");

    return ranges.some((range) => {
      if (!range || range.length < 2 || !range[0] || !range[1]) {
        return false;
      }

      const start = moment(range[0], "HH:mm");
      const end = moment(range[1], "HH:mm");

      return timeToCheck.isSameOrAfter(start) && timeToCheck.isSameOrBefore(end);
    });
  };

  const renderWeekCalendar = () => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(startOfWeek.clone().add(i, 'days'));
    }

    return (
      <div className="week-calendar">
        <div className="calendar-header">
          <div className="time-column-header"></div>
          {days.map((day, index) => (
            <div
              key={index}
              className={`day-column-header ${moment().isSame(day, 'day') ? 'today' : ''}`}
            >
              {day.format('D.MM.YYYY')}
            </div>
          ))}
        </div>
        <div className="calendar-body">
          <div className="time-column">
            <div className="time-cell">Zakázky nevázané na čas</div>
            {Array.from({ length: 24 }, (_, hour) => (
              <div key={hour} className={`time-cell ${ hour % 2 ? '' : 'odd'}`}>{hour}:00</div>
            ))}
          </div>
          {days.map((day, dayIndex) => (
            <div key={dayIndex} className="day-column">
              <div className={`time-slot ${withoutTime[dayIndex].length ? 'red' : ''}`}>
                {withoutTime[dayIndex]?.map((item, itemIndex) => (
                  <span
                    key={itemIndex}
                    className="ant-tag"
                    style={{
                      maxWidth: '100%',
                      fontSize: '10px',
                      padding: '2px 5px',
                      margin: '0px',
                      lineHeight: '10px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                  <span>
                    {item.building_unit.street} {item.building_unit.house_number}
                  </span>
                </span>
                ))}
              </div>
              {Array.from({ length: 24 }, (_, hour) => {
                const currentHour = day.clone().hour(hour);
                const events = withTime[dayIndex].filter((event) =>
                  moment(event.date_real ?? event.date_expected).isSame(currentHour, 'hour')
                );
                const green = isTimeWithinRange(welcomeTime,dayIndex,hour) ? 'green' : '';
                const odd = hour % 2 ? '' : 'odd';
                return (
                  <div key={hour} className={`time-slot ${events.length > 0 ? 'red' : ''} ${green} ${odd}`}>
                    {events.length > 0 && events.map((event, index) => (
                      <span
                        key={index}
                        className="ant-tag"
                        style={{
                          maxWidth: '100%',
                          fontSize: '10px',
                          padding: '2px 5px',
                          margin: '0px',
                          lineHeight: '10px',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                      <span>
                        {event.building_unit.street} {event.building_unit.house_number}
                      </span>
                    </span>
                    ))}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderWeekCalendar()}
    </div>
  );
};

export default PartnerCustomCalendar;
